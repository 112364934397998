import React from 'react'
import { TextField, FormControl } from '@mui/material'
import PropTypes from 'prop-types'

export const InputTextField = (props) => {
  const {
    type,
    label,
    name,
    value,
    id,
    required,
    placeholder,
    onChange,
    error
  } = props
  return (
    <React.Fragment>
      <FormControl fullWidth sx={{ height: '4.5em' }} >
        <TextField
          sx={{
            '& .MuiInputLabel-root': {
              mt: -0.3
            },
            '& .MuiInputLabel-shrink': {
              mt: 0.2
            },
            '& .MuiFormHelperText-root': {
              mt: -0.25
            }
          }}
          type={type}
          size='small'
          variant='outlined'
          margin='normal'
          label={label}
          name={name}
          value={value}
          id={id}
          required={required}
          helperText={error}
          placeholder={placeholder}
          onChange={onChange}
          error={Boolean(error)}
        />
      </FormControl>
    </React.Fragment>
  )
}

InputTextField.defaultValues = {
  type: 'text',
  placeholder: ' ',
  error: undefined
}

InputTextField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string
}
