import React from 'react'
import { Link } from 'react-router-dom'

const RecoveredPasswordBanner = () => {
  return (
    <p>Паролата е сменена, можеш да <Link to='/login'>влезеш</Link> в сервиза сега.</p>
  )
}

export default RecoveredPasswordBanner
