import { Api, getTokenFromCookie, removeTokenCookie, setTokenToCookie } from './backendCommons'
// import { getCallerName } from './debug'

export const isCookieLoggedIn = async () => {
  const userToken = getTokenFromCookie()
  if (userToken) {
    try {
      const response = await Api.get(`/L1/get_user_byToken/${userToken}`)
      return response.status === 200
    } catch (error) {
      console.error('Error getting user info: ', error)
    }
  } else {
    console.error('No token cookie found')
  }
  return false
}

export const getProfileByCookie = async (checkLogged = false) => {
  // console.log(`getProfileByCookie() called by ${getCallerName()}`)
  const userToken = getTokenFromCookie()
  if (userToken) {
    try {
      const response = await Api.get(`/L1/get_user_byToken/${userToken}`)
      if (response.status === 200) {
        // console.log('user data returned:', response.data)
        return response.data
      }
    } catch (error) {
      console.error('Error getting user info: ', error)
    }
  } else {
    console.error('No token cookie found, ignore if it was not mandatory at the moment')
  }
  return null
}

const loginUser = async (email, password, setTokenCookie = true) => {
  try {
    const response = await Api.post('/L0/login_user', { email, password })
    if (response.status === 200) {
      if (setTokenCookie) {
        setTokenToCookie(response.data.token)
      }
      return { message: 'OK', token: response.data.token }
    } else {
      console.error('User auth server error caught:', response.data)
    }
  } catch (error) {
    console.error('User auth client error caught', error)
  }
  return { message: 'unauthorized' }
}

const logoutUser = async (clearCookie = true) => {
  const userToken = getTokenFromCookie()
  try {
    const response = await Api.post('/L1/logout_user/', { token: userToken })
    if (response.status === 200) {
      if (clearCookie) {
        removeTokenCookie()
      }
      return true
    } else {
      console.error('User logout server error caught:', response.data)
    }
  } catch (error) {
    console.error('User logout client error caught:', error)
  }
  return false
}

const registerUser = async (email, password) => {
  try {
    const response = await Api.post('/L0/register_user', { email, password })
    if (response.status === 200) {
      return 'OK'
    } else {
      console.error('RegisterUser() got non-200 response:', response)
      return response.data.message
    }
  } catch (error) {
    switch (error.response.status) {
      case 400: {
        return error.response.data.message
      }
    }
    console.error('RegisterUser() caught error:', error)
    return 'Unknown client error'
  }
}

const sendConfirmToken = async (token) => {
  try {
    const response = await Api.post('/L0/confirm_email/', { token })
    if (response.status === 200) {
      return 'OK'
    } else {
      console.error('sendConfirmToken() got non-200 response:', response)
      return response.data.message
    }
  } catch (error) {
    switch (error.response.status) {
      case 400: {
        return error.response.data.message
      }
    }
    console.error('sendConfirmToken() caught error:', error)
    return 'Unknown client error'
  }
}

const requestPassword = async (email) => {
  try {
    const response = await Api.post('/L0/request_password/', { email })
    if (response.status === 200) {
      return 'OK'
    } else {
      console.error('requestPassword() got non-200 response:', response)
      return response.data.message
    }
  } catch (error) {
    switch (error.response?.status) {
      case 400: {
        return error.response.data.message
      }
    }
    console.error('requestPassword() caught error:', error)
    return 'Unknown client error'
  }
}

export const checkPassRecoveryToken = async (token) => {
  try {
    const response = await Api.get(`/L0/check_prt/${token}`)
    if (response.status === 200) {
      return true
    }
  } catch (error) {
    return false
  }
  return false
}

export const recoverPassword = async (token, password) => {
  try {
    const response = await Api.post('/L0/recover_password/', { token, password })
    if (response.status === 200) {
      return 'OK'
    } else {
      console.error('recoverPassword() got non-200 response:', response)
      return response.data.message
    }
  } catch (error) {
    switch (error.response.status) {
      case 400: {
        return error.response.data.message
      }
    }
    console.error('recoverPassword() caught error:', error)
    return 'Unknown client error'
  }
}

export {
  loginUser,
  logoutUser,
  registerUser,
  sendConfirmToken,
  requestPassword
}
