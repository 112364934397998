import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getUsersList } from '../utils/admin'

const UsersList = () => {
  const [usersList, setUsersList] = useState([])
  const [usersListLoaded, setUsersListLoaded] = useState(false)

  useEffect(() => {
    const fetchUsersList = async () => {
      const result = await getUsersList()
      setUsersList(result)
    }

    if (!usersListLoaded) {
      fetchUsersList()
      setUsersListLoaded(true)
    }
  }, [usersListLoaded])

  // console.log('UsersListLoaded:', usersListLoaded)
  return (
    <TableContainer component={Paper} sx={{ margin: '2em', width: '95%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Confirmed</TableCell>
            <TableCell>Access level</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersListLoaded && usersList.map((row) => (
            <TableRow key={row.id} >
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.confirmed ? 'yes' : 'no'}</TableCell>
              <TableCell>{row.access_level}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UsersList
