import React, { useEffect, useState } from 'react'
import { requestPassword } from '../utils/auth'
import { PageState } from '../utils/declarations'
import { Box, Button, Container, CssBaseline, Typography } from '@mui/material'
import { InputTextField } from './InputComponent'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContex'

const RequestPasswordForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    formErrors: {},
    formState: PageState.USERWAIT
  })

  const { isAuthenticated, checkAuth } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated === undefined) {
      checkAuth()
    }
    if (isAuthenticated === true) {
      navigate('/')
    }
  }, [isAuthenticated])

  const handleChange = event => {
    const { name, value } = event.target
    const errors = formData.formErrors
    switch (name) {
      case 'email': delete errors.email; break
    }
    setFormData(prevState => ({ ...prevState, formErrors: errors }))
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!formData.email) {
      console.error('empty email')
    }
    setFormData(prevState => ({ ...prevState, formState: PageState.SERVERWAIT }))
    const reqResult = await requestPassword(formData.email)
    console.log('request result:', reqResult)
    if (reqResult) {
      setFormData(prevState => ({ ...prevState, formState: PageState.FINALIZED }))
    } else {
      console.error('Password request failed')
      setFormData(prevState => ({ ...prevState, formState: PageState.USERWAIT }))
    }
  }

  switch (formData.formState) {
    case PageState.USERWAIT:
    case PageState.SERVERWAIT: {
      return (
        <Container component='main' maxWidth='xs' sx={{ alignItems: 'center' }}>
          <CssBaseline />
          <Box>
            <Typography component='h1' variant='h5' sx={{ m: 1, textAlign: 'center' }}>
              Забравена парола?
            </Typography>
            <form onSubmit={handleSubmit}>
              <InputTextField
                type='email'
                label='Имейл'
                name='email'
                id='email'
                required
                value={formData.email}
                onChange={handleChange}
                error={formData.formErrors.email}
              />
              <Button
                variant='contained'
                type='submit'
                fullWidth
                sx={{ mt: 2 }}
                {...(formData.formState === PageState.USERWAIT && { enabled: 'true' })}
              >
                Възстанови
              </Button>
            </form>
          </Box>
        </Container>
      )
    }
    case PageState.FINALIZED: {
      return (
        <Container component='main' maxWidth='xs' sx={{ alignItems: 'center' }}>
          <CssBaseline />
          <Box>
            <Typography component='h1' variant='h5' sx={{ m: 1, textAlign: 'center' }}>
              Забравена парола?
            </Typography>
            <Typography component='div'>
              Писмо за възстановяване на парола е изпратено към имейл <i><u>{formData.email}</u></i>.<br />
              Провери поща (ако няма във входяща - провери &quot;спам&quot;).<br />
              По същото време можеш да отидеш към <Link to="/">главна страница</Link>
            </Typography>
          </Box>
        </Container>
      )
    }
  }
}

export default RequestPasswordForm
