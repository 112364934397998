export const PageState = Object.freeze({
  UNDEFINED: -1,
  USERWAIT: 0,
  SERVERWAIT: 1,
  SERVERERROR: 2, // unrecoverable error
  FINALIZED: 3
})

export const AccessLevel = Object.freeze({
  GUEST: 0,
  COMMON: 1,
  ADVANCED: 2,
  EDITOR: 3,
  ADMIN: 4
})
