import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { checkPassRecoveryToken, recoverPassword } from '../utils/auth'
import { PageState } from '../utils/declarations'
import { Box, Button, Container, CssBaseline, Typography } from '@mui/material'
import { InputTextField } from './InputComponent'
import { useAuth } from './AuthContex'
import RecoveredPasswordBanner from './RecoveredPasswordBanner'

const RecoverPasswordForm = () => {
  const { token: tokenParam } = useParams()

  const navigate = useNavigate()

  const { isAuthenticated, checkAuth } = useAuth()

  const [doesTokenExist, setTokenExists] = useState(undefined)

  useEffect(() => {
    if (isAuthenticated === undefined) {
      checkAuth()
    }
    if (isAuthenticated === true) {
      navigate('/')
    }
  }, [isAuthenticated])

  const [formData, setFormData] = useState({
    password: '',
    password2: '',
    formErrors: {},
    formState: PageState.USERWAIT
  })

  const handleChange = event => {
    const { name, value } = event.target
    const errors = formData.formErrors
    switch (name) {
      case 'password': delete errors.password; break
      case 'password2': delete errors.password2; break
    }
    setFormData(prevState => ({ ...prevState, formErrors: errors }))
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }

  useEffect(() => {
    const checkToken = async () => {
      if (typeof tokenParam === 'string' && doesTokenExist === undefined) {
        const result = await checkPassRecoveryToken(tokenParam)
        setTokenExists(result)
      }
    }

    checkToken()
  }, [tokenParam])

  useEffect(() => {
    if (doesTokenExist === false) {
      navigate('/')
    }
  }, [doesTokenExist])

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formResult = validateForm()
    if (Object.keys(formResult).length === 0) {
      setFormData(prevState => ({ ...prevState, formState: PageState.SERVERWAIT }))
      const recResult = await recoverPassword(tokenParam, formData.password)
      switch (recResult) {
        case 'OK': {
          setFormData(prevState => ({ ...prevState, formState: PageState.FINALIZED }))
          break
        }
        default: {
          setFormData(prevState => ({ ...prevState, formState: PageState.USERWAIT }))
          console.error('Error:', recResult)
        }
      }
    } else {
      console.error('Form validate errors:', formResult)
    }
  }

  const validateForm = () => {
    const errors = {}
    if (formData.password === '') {
      errors.password = 'Парола не може да е празна!'
    }
    if (formData.password !== formData.password2) {
      errors.password2 = 'Паролите са различни!'
    }
    setFormData(prevState => ({ ...prevState, formErrors: errors }))
    return errors
  }

  if (formData.formState === PageState.FINALIZED) {
    return (
      <RecoveredPasswordBanner email={formData.email} message='OK'/>
    )
  } else {
    return (
      <Container component='main' maxWidth='xs' sx={{ alignItems: 'center' }}>
        <CssBaseline />
        <Box>
          <Typography component='h1' variant='h5' sx={{ m: 1 }}>
            Въведи новата си парола
          </Typography>
          <form onSubmit={handleSubmit}>
            <InputTextField
              type='password'
              label='Парола'
              name='password'
              id='password'
              required
              value={formData.password}
              onChange={handleChange}
              error={formData.formErrors.password}
            />
            <InputTextField
              type='password'
              label='Парола отново'
              name='password2'
              id='password2'
              required
              value={formData.password2}
              onChange={handleChange}
              error={formData.formErrors.password2}
            />
            <Button
              variant='contained'
              type='submit'
              fullWidth
              sx={{ mt: 2 }}
              {...(formData.formState === PageState.USERWAIT && { enabled: 'true' })}
            >
              Напред!
            </Button>
          </form>
        </Box>
      </Container>
    )
  }
}

export default RecoverPasswordForm
