import React, { useEffect } from 'react'
import { useAuth } from '../components/AuthContex'
import { useNavigate } from 'react-router-dom'
import RegisterForm from '../components/RegisterForm'
import { StandardPageWrapper } from '../components/StandardPageWrapper'

const RegisterPage = () => {
  const { isAuthenticated, checkAuth } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    const checkIsAuth = async () => {
      switch (isAuthenticated) {
        case undefined: {
          await checkAuth()
          break
        }
        case true: {
          navigate('/')
          break
        }
      }
    }

    checkIsAuth()
  }, [isAuthenticated])

  if (isAuthenticated === false) {
    return (
      <StandardPageWrapper inPaper>
        <RegisterForm />
      </StandardPageWrapper>
    )
  } else {
    return (<React.Fragment />)
  }
}

export default RegisterPage
