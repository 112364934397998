import React from 'react'
import { StandardPageWrapper } from '../components/StandardPageWrapper'
import GdprBanner from '../components/GdprBanner'

const GdprPage = () => {
  return (
    <StandardPageWrapper inPaper>
      <GdprBanner />
    </StandardPageWrapper>
  )
}

export default GdprPage
