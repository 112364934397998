import React from 'react'
import './App.css'
import { AuthProvider } from './components/AuthContex.jsx'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import HomePage from './pages/HomePage.jsx'
import ConfirmEmailPage from './pages/ConfirmEmailPage.jsx'
import RequestPasswordPage from './pages/RequestPasswordPage.jsx'
import HomeUnregisteredPage from './pages/HomeUnregisteredPage.jsx'
import RecoverPasswordPage from './pages/RecoverPasswordPage.jsx'
import LoginPage from './pages/LoginPage.jsx'
import RegisterPage from './pages/RegisterPage.jsx'
import UserProfilePage from './pages/UserProfilePage.jsx'
import GdprPage from './pages/GdprPage.jsx'
import UsersListPage from './pages/UsersListPage.jsx'
import PageNotFound from './pages/PageNotFound.jsx'

function App () {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/unregistered" element={<HomeUnregisteredPage />} />
          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/profile" element={<UserProfilePage />} />
          <Route exact path="/gdpr" element={<GdprPage />} />
          <Route path="/confirm_email/:token" element={<ConfirmEmailPage />} />
          <Route exact path="/request_password" element={<RequestPasswordPage />} />
          <Route path="/recover_password/:token" element={<RecoverPasswordPage />} />
          <Route path="/admin/userslist" element={<UsersListPage />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
