import React, { useEffect } from 'react'
import { useAuth } from '../components/AuthContex'
import { useNavigate } from 'react-router-dom'
import { UserProfile } from '../components/UserProfile'
import { StandardPageWrapper } from '../components/StandardPageWrapper'

const UserProfilePage = () => {
  const { isAuthenticated, checkAuth } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    const checkIsAuth = async () => {
      switch (isAuthenticated) {
        case undefined: {
          await checkAuth()
          break
        }
        case false: {
          navigate('/')
          break
        }
      }
    }

    checkIsAuth()
  }, [isAuthenticated])

  if (isAuthenticated === true) {
    return (
      <StandardPageWrapper inPaper>
        <UserProfile />
      </StandardPageWrapper>
    )
  } else {
    return (<React.Fragment />)
  }
}

export default UserProfilePage
