import React, { useEffect } from 'react'
import { Button, Grid, Typography, Box } from '@mui/material/'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContex'

const UnregisteredBanner = () => {
  const navigate = useNavigate()

  const { isAuthenticated, checkAuth } = useAuth()

  useEffect(() => {
    if (isAuthenticated === undefined) {
      checkAuth()
    }
    if (isAuthenticated === true) {
      navigate('/')
    }
  }, [isAuthenticated])

  const navigateToRegister = (event) => {
    event.preventDefault()
    navigate('/register')
  }

  const navigateToLogin = (event) => {
    event.preventDefault()
    navigate('/login')
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={6} padding={2}>
        <Typography component='h1' variant='h4' sx={{ mb: 2 }}>Здравей!</Typography>
        <Typography component='div'>
          <p>Този сервиз ще ти помогне да спестяваш парите!</p>
          <p>Регистрация изисква само имейл и няма да ти отнеме време.</p>
          <p>Търси цени за различни стоки, споделяй своите касови бележки и участвай в томболи!</p>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <Grid item xs={12} sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 2
          }}>
            <Button variant="contained" onClick={navigateToRegister} fullWidth>Искам да се регистрирам!</Button>
            <br />
            <br />
            <Button variant="contained" onClick={navigateToLogin} fullWidth>Вече регистриран съм!</Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default UnregisteredBanner
