import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { useAuth } from '../components/AuthContex'
import { useNavigate } from 'react-router-dom'
import { StandardPageWrapper } from '../components/StandardPageWrapper'

const HomePage = () => {
  const { isAuthenticated, checkAuth } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    const checkIsAuth = async () => {
      switch (isAuthenticated) {
        case undefined: {
          await checkAuth()
          break
        }
        case false: {
          navigate('/unregistered')
          break
        }
      }
    }
    // console.log('Home: isAuthenticated changed:', isAuthenticated)
    checkIsAuth()
  }, [isAuthenticated])

  if (isAuthenticated === true) {
    return (
      <StandardPageWrapper inPaper>
        <Typography component='h1' variant='h4'>Основна страница</Typography>
      </StandardPageWrapper>
    )
  } else {
    return (<React.Fragment />)
  }
}

export default HomePage
