import React from 'react'
import { Box, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

const UnauthorizedBanner = () => {
  return (
    <Box sx={{ margin: '2em' }}>
      <Typography component='h1' variant='h4'><CancelIcon /> Достъп е отказан!</Typography>
      <p>Съжаляваме, но нямате право на достъп до тази страница. Вероятно сте опитали да достъпите ресурс, към който нямате права или който е забранен за вас.</p>
      <p>Моля, проверете дали сте въвели правилния адрес или се свържете с администратора на сайта, ако смятате, че сте получили тази грешка по погрешка.</p>
      <p>Благодарим ви за разбирането.</p>
    </Box>
  )
}

export default UnauthorizedBanner
