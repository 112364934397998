import React from 'react'
import { IconButton, InputBase, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const SearchBar = () => {
  return (
    <Paper
      component="form"
      sx={{ display: 'flex', alignItems: 'center', paddingLeft: '1em' }}
    >
      <InputBase
        placeholder='Търсене'
      />
      <IconButton type='button'>
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchBar
