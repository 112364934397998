import axios from 'axios'
import Cookies from 'js-cookie'

const BACKEND_PORT_VARNAME = 'REACT_APP_BACKEND_PORT'
const BACKEND_URL_VARNAME = 'REACT_APP_BACKEND_URL'
export const TOKEN_COOKIE_NAME = 'USER_TOKEN'
const DEV_ENVIRONMENT_VARNAME = 'REACT_APP_DEV_ENVIRONMENT'

const BackendURL = () => {
  // console.log(process.env[BACKEND_URL], process.env[BACKEND_PORT])
  if (process.env[BACKEND_URL_VARNAME]) {
    return process.env[BACKEND_URL_VARNAME]
  } else if (window.env && window.env[BACKEND_URL_VARNAME]) {
    return window.env[BACKEND_URL_VARNAME]
  } else {
    const defaultPort = process.env[BACKEND_PORT_VARNAME] || 50080
    return `${window.location.protocol}//${window.location.hostname}:${defaultPort}`
  }
}

export const Api = axios.create({
  baseURL: BackendURL(),
  withCredentials: process.env[DEV_ENVIRONMENT_VARNAME] === 'False',
  timeout: 60000
})

export const getTokenFromCookie = () => {
  const token = Cookies.get(TOKEN_COOKIE_NAME)
  return token
}

export const setTokenToCookie = (token) => {
  Cookies.set(TOKEN_COOKIE_NAME, token, { sameSite: 'strict', expires: 30 })
}

export const removeTokenCookie = () => {
  Cookies.remove(TOKEN_COOKIE_NAME)
}
