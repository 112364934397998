import React from 'react'
import { Paper, Stack } from '@mui/material'
// import { TopBarComponent } from './TopBarComponent'
import PropTypes from 'prop-types'
import { MenuComponent } from './MenuComponent'

export const StandardPageWrapper = ({ children, inPaper = false }) => {
  return (
    <Stack>
      <MenuComponent />
      { inPaper
        ? (
          <Stack
            direction="column"
            alignItems="center"
          >
            <Paper
              elevation={1}
              xs={12} sm={10} md={8} lg={6}
              sx={{ margin: 2, padding: 2, width: '85%', maxWidth: '40em' }}
            >
              {children}
            </Paper>
          </Stack>
          )
        : (children)
      }
    </Stack>
  )
}

StandardPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  inPaper: PropTypes.bool
}
