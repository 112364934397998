import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { useAuth } from './AuthContex'
import { PageState } from '../utils/declarations'
import { useNavigate } from 'react-router-dom'

const LogoutButton = () => {
  const { isAuthenticated, checkAuth, handleLogout } = useAuth()
  const [formState, setFormState] = useState(PageState.USERWAIT)

  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated === undefined) {
      checkAuth()
    }
    if (isAuthenticated === false) {
      navigate('/')
    }
  }, [isAuthenticated])

  const doLogout = async (event) => {
    setFormState(PageState.SERVERWAIT)
    const result = await handleLogout()
    if (result) {
      window.location.reload()
    } else {
      setFormState(PageState.SERVERERROR)
    }
  }

  return (
    <React.Fragment>
      {isAuthenticated && (
        <div>
        <Button
            variant='contained'
            onClick={doLogout}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
            enabled={(formState === PageState.USERWAIT) ? 'enabled' : {}}
          >
            Изход
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}

export default LogoutButton
