import React, { useEffect, useState } from 'react'
import { useAuth } from '../components/AuthContex'
import { AccessLevel } from '../utils/declarations'
import { StandardPageWrapper } from '../components/StandardPageWrapper'
import UnauthorizedBanner from '../components/UnauthorizedBanner'
import UsersList from '../components/UsersList'

const UsersListPage = () => {
  const { isAuthenticated, checkAuth, user } = useAuth()
  const [pageState, setPageState] = useState(undefined)

  useEffect(() => {
    // console.log(`UserListPageEffect(${isAuthenticated})`)
    if (isAuthenticated === undefined) {
      checkAuth()
    }
    if (isAuthenticated !== true) {
      setPageState(isAuthenticated)
    } else {
      if (user === null || user === undefined || user.access_level === undefined) {
        setPageState(undefined)
      } else {
        setPageState(user.access_level >= AccessLevel.ADMIN)
      }
    }
    // console.log('userData in UserListPageEffect:', user)
  }, [isAuthenticated])

  switch (pageState) {
    case false: {
      // console.log('not authenticated')
      return (
        <StandardPageWrapper>
          <UnauthorizedBanner />
        </StandardPageWrapper>
      )
    }
    case true: {
      // console.log('authenticated')
      return (
        <StandardPageWrapper>
          <UsersList />
        </StandardPageWrapper>
      )
    }
    default: {
      // console.log('authentication awaits')
      return (
        <></>
      )
    }
  }
}

export default UsersListPage
