import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { getProfileByCookie, loginUser, logoutUser } from '../utils/auth'
import { Navigate, Outlet } from 'react-router-dom'

const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isCheckingAuth, setCheckingAuth] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(undefined)

  const handleLogin = async (login, password) => {
    const result = await loginUser(login, password, true)
    if (result.message === 'OK') {
      setIsAuthenticated(true)
    }
    checkAuth()
    return result
  }

  const checkAuth = async () => {
    if (isCheckingAuth === true) {
      return
    }
    setCheckingAuth(true)
    const userData = await getProfileByCookie(true)
    if (userData !== null) {
      setUser(userData)
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
      setUser(null)
    }
    setCheckingAuth(false)
  }

  const handleLogout = async () => {
    await logoutUser(true)
    sessionStorage.removeItem('userdata')
    setUser(null)
    setIsAuthenticated(false)
  }

  return (
    <AuthContext.Provider value={{
      user, isAuthenticated, checkAuth, handleLogin, handleLogout
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export const PrivateRoute = () => {
  const { isAuthenticated, checkAuth } = useContext(AuthContext)
  if (isAuthenticated === undefined) {
    checkAuth()
  }
  switch (isAuthenticated) {
    case true: {
      return <Outlet />
    }
    case false: {
      return <Navigate to='/unregistered' replace />
    }
    case undefined: {
      return <React.Fragment />
    }
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}
