import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const RegisteredBanner = (props) => {
  const { email, message } = props

  return (
    <React.Fragment>
      {email
        ? (<p>Имейл <i>{email}</i> е приет за регистрация, провери поща (ако няма във входяща &mdash; провери спам) и/или отиди към <Link to="/">главна страница</Link></p>)
        : (message === 'same email'
            ? (<p>Нещо не е на ред, отиди към <Link to="/">главна страница</Link> или <Link to="/register">се регистрирай отново</Link></p>)
            : (<p>Този имейл вече е регистриран, <Link to="/login">влез</Link> или промени го като <Link to="/register">се регистрирай отново</Link></p>)
          )
      }
    </React.Fragment>
  )
}

RegisteredBanner.propTypes = {
  email: PropTypes.string,
  message: PropTypes.string
}

export default RegisteredBanner
