import { Typography } from '@mui/material'
import React from 'react'

const GdprBanner = () => {
  return (
    <>
      <Typography component='h1' variant='h4'>Информация за Регламентa за Общата защита на данните (GDPR):</Typography>
      <p>Използваме бисквитки, за основно функциониране на сайта, да персонализираме съдържание и реклами, да предоставим функции за социални мрежи и да анализираме трафика. Също така споделяме информация за вашата употреба на нашия уебсайт с нашите партньори за социални мрежи, реклама и анализ. Като продължавате да използвате нашия уебсайт, вие се съгласявате с нашето използване на бисквитки и практиките за събиране на данни, както е описано в нашата Политика за поверителност.</p>
      <Typography component='h2' variant='h5'>Google AdSense:</Typography>
      <p>Уебсайтът може да използва Google AdSense за показване на персонализирани реклами. Моля, имайте предвид, че докато ние обработваме някои лични данни вътрешно, определена информация се събира и от трети страни, като например Google AdSense. Тези услуги от трети страни могат да събират данни за персонализация на реклами и за измерване на рекламната ефективност. Можете да научите повече за начина, по който Google използва данни и как да се откажете, като посетите Политиката за поверителност и Условията на Google.</p>
      <p>С продължаването да използвате нашия уебсайт, вие се съгласявате с използването на бисквитки и практиките за събиране на данни, описани по-горе.</p>
    </>
  )
}

export default GdprBanner
