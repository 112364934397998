import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { sendConfirmToken } from '../utils/auth'
import { PageState } from '../utils/declarations'
import { Typography } from '@mui/material'
import { StandardPageWrapper } from '../components/StandardPageWrapper'

const ConfirmEmailPage = () => {
  const { token } = useParams()
  const [formState, setFormState] = useState(PageState.UNDEFINED)
  const [message, setMessage] = useState('')

  const effectRan = useRef(false)

  useEffect(() => {
    const sendToken = async () => {
      console.log('Effect triggered once')
      setFormState(PageState.SERVERWAIT)
      const result = await sendConfirmToken(token)
      switch (result) {
        case 'OK': {
          setFormState(PageState.FINALIZED)
          break
        }
        default: {
          setFormState(PageState.SERVERERROR)
        }
      }
    }

    if (!effectRan.current) {
      sendToken()
    }
    effectRan.current = true
  }, [])

  useEffect(() => {
    switch (formState) {
      case PageState.SERVERWAIT: {
        setMessage('Имейл се проверява...')
        break
      }
      case PageState.FINALIZED: {
        setMessage('Имейл е потвърден')
        break
      }
      default: {
        setMessage('Имейл не е потвърден')
      }
    }
  }, [formState])

  return (
    <StandardPageWrapper inPaper>
      <Typography variant="h5">{ message }</Typography>
    </StandardPageWrapper>
  )
}

export default ConfirmEmailPage
