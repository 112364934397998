import { Api, getTokenFromCookie } from './backendCommons'

export const getUsersList = async () => {
  const reqToken = getTokenFromCookie()
  // console.log(`getUsersList for token ${reqToken}`)
  const response = await Api.get(`/L4/get_users_list/${reqToken}`)
  if (response.status === 200) {
    return response.data
  } else {
    return []
  }
}
