import React from 'react'
import { StandardPageWrapper } from '../components/StandardPageWrapper'
import { Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'

const PageNotFound = () => {
  const location = useLocation()
  return (
    <StandardPageWrapper inPaper>
      <Typography component='h1' variant='h4'>Страницата не е намерена</Typography>
      <p>Съжаляваме, но страницата &quot;{location.pathname}&quot;, която се опитвате да достъпите, не може да бъде намерена. Възможно е страницата да бъде преместена, да бъде временно недостъпна или да бъде изтрита.</p>
      <p>Моля, проверете дали сте въвели правилния адрес или опитайте да потърсите търсената информация отново. Ако проблемът продължава, моля, свържете се с нас за допълнителна помощ.</p>
      <p>Благодарим ви за разбирането.</p>
    </StandardPageWrapper>
  )
}

export default PageNotFound
