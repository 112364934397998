import { AppBar, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, SwipeableDrawer, ThemeProvider, Toolbar, createTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAuth } from './AuthContex'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import HelpIcon from '@mui/icons-material/Help'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'

import ProjectLogo from './ProjectLogo'
import SearchBar from './SearchBar'

const theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          paddingRight: '0.5em',
          boxSizing: 'content-box'
        }
      }
    }
  }
})

export const MenuComponent = () => {
  const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { isAuthenticated, checkAuth, handleLogout } = useAuth()

  useEffect(() => {
    if (isAuthenticated === undefined) {
      checkAuth()
    }
  }, [isAuthenticated])

  const handleMenu = (item) => {
    const handlers = {
      home: () => navigate('/'),
      unregistered: () => navigate('/unregistered'),
      register: () => navigate('/register'),
      login: () => navigate('/login'),
      profile: () => navigate('/profile'),
      gdpr: () => navigate('/gdpr'),
      exit: () => handleLogout()
    }
    setIsDrawerOpen(false)
    handlers[item]?.()
  }

  const DrawerMenuItem = (props) => {
    const { isDivider, onClick, IconComponent, text, authStateRequired } = props
    if (authStateRequired === undefined || (authStateRequired === isAuthenticated)) {
      if (isDivider) {
        return (<Divider />)
      } else {
        return (
          <ListItemButton onClick={onClick}>
            <IconComponent />
            <ListItemText primary={text} />
          </ListItemButton>
        )
      }
    } else {
      return (null)
    }
  }

  DrawerMenuItem.propTypes = {
    isDivider: PropTypes.bool,
    onClick: PropTypes.func,
    IconComponent: PropTypes.elementType,
    text: PropTypes.string,
    authStateRequired: PropTypes.bool
  }

  DrawerMenuItem.defaultProps = {
    isDivider: false,
    onClick: undefined,
    IconComponent: undefined,
    text: '',
    authStateRequired: undefined
  }

  const menuItems = [
    { isDivider: true },
    { onClick: () => handleMenu('home'), IconComponent: HomeIcon, text: 'Начало' },
    { authStateRequired: true, onClick: () => handleMenu('profile'), IconComponent: AccountCircleIcon, text: 'Профил' },
    { authStateRequired: false, onClick: () => handleMenu('register'), IconComponent: PersonAddIcon, text: 'Регистрация' },
    { authStateRequired: false, onClick: () => handleMenu('login'), IconComponent: LoginIcon, text: 'Вход' },
    { isDivider: true },
    { onClick: () => handleMenu('help'), IconComponent: HelpIcon, text: 'Помощ' },
    { onClick: () => handleMenu('gdpr'), IconComponent: PrivacyTipIcon, text: 'Поверителност' },
    { isDivider: true, authStateRequired: true },
    { authStateRequired: true, onClick: () => handleMenu('exit'), IconComponent: LogoutIcon, text: 'Изход' }
  ]

  return (
    <ThemeProvider theme={theme}>
      <AppBar elevation={0} variant='outlined' position='fixed'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={() => setIsDrawerOpen(true)}
          >
            <MenuIcon />
            <ProjectLogo style={{ fontSize: 60 }} />
          </IconButton>
          { isAuthenticated === true && (
            <SearchBar />
          )}
          <SwipeableDrawer
            disableScrollLock={true}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            onOpen={() => {}}
          >
            <List>
              <ListItem
                onClick={() => setIsDrawerOpen(false)}
                sx={{ height: '5.0em' }}
              >
                <MenuIcon />
                <ProjectLogo style={{ fontSize: 60 }} />
                <ListItemText primary="" />
              </ListItem>
              {menuItems.map((item, index) => (
                <DrawerMenuItem key={index} {...item} />
              ))}
            </List>
          </SwipeableDrawer>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </ThemeProvider>
  )
}
