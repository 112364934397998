import React, { useEffect, useState } from 'react'
import { InputTextField } from './InputComponent'
import { PageState } from '../utils/declarations'
import { Box, Button, Container, CssBaseline, Typography } from '@mui/material'
import { useAuth } from './AuthContex'
import { Link, useNavigate } from 'react-router-dom'

const LoginForm = () => {
  const { isAuthenticated, checkAuth, handleLogin } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated === undefined) {
      checkAuth()
    }
    if (isAuthenticated === true) {
      navigate('/')
    }
  }, [isAuthenticated])

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password2: '',
    formErrors: {},
    formState: PageState.USERWAIT
  })

  const handleChange = event => {
    const { name, value } = event.target
    const errors = formData.formErrors
    switch (name) {
      case 'email': {
        delete errors.email
        delete errors.credentials
        break
      }
      case 'password': {
        delete errors.password
        delete errors.credentials
        break
      }
    }
    setFormData(prevState => ({ ...prevState, formErrors: errors }))
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }

  const validateForm = () => {
    const errors = {}
    if (formData.email === '') {
      errors.email = 'Потребителски имейл не може да е празен'
    }
    if (formData.password === '') {
      errors.password = 'Парола не може да е празна'
    }
    setFormData(prevState => ({ ...prevState, formErrors: errors }))
    return errors
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formResult = validateForm()
    if (Object.keys(formResult).length === 0) {
      setFormData(prevState => ({ ...prevState, formState: PageState.SERVERWAIT }))
      const loginResult = await handleLogin(formData.email, formData.password)
      switch (loginResult.message) {
        case 'OK': {
          navigate('/')
          break
        }
        case 'unauthorized': {
          // console.error('Logging in failed')
          setFormData(prevState => ({ ...prevState, formErrors: { credentials: 'Грешен имейл или парола' } }))
          setFormData(prevState => ({ ...prevState, formState: PageState.USERWAIT }))
          break
        }
        default: {
          console.error('login result (something went wrong):', loginResult)
          setFormData(prevState => ({ ...prevState, formState: PageState.SERVERERROR }))
        }
      }
    } else {
      console.error('Error:', formResult)
      setFormData(prevState => ({ ...prevState, formState: PageState.USERWAIT }))
    }
  }

  return (
    <Container component='main' maxWidth='xs' sx={{ alignItems: 'center' }}>
    <CssBaseline />
      <Box>
        <Typography component='h1' variant='h5' sx={{ m: 1, textAlign: 'center' }}>
          Вход в сервиз
        </Typography>
        <form onSubmit={handleSubmit}>
          <InputTextField
            type='email'
            label='Имейл'
            name='email'
            id='email'
            required
            value={formData.email}
            onChange={handleChange}
            error={formData.formErrors.email || formData.formErrors.credentials}
          />
          <InputTextField
            type='password'
            label='Парола'
            name='password'
            id='password'
            required
            value={formData.password}
            onChange={handleChange}
            error={formData.formErrors.password || formData.formErrors.credentials}
          />
          <Button
            variant='contained'
            type='submit'
            fullWidth
            sx={{ mt: 2, mb: 2 }}
            {...(formData.formState === PageState.USERWAIT && { enabled: 'true' })}
          >
            Напред!
          </Button>
          <Link to="/request_password">Забравена парола</Link>
        </form>
      </Box>
    </Container>
  )
}

export default LoginForm
