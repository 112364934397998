import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useAuth } from './AuthContex'
import { useNavigate } from 'react-router-dom'
import LogoutButton from './LogoutButton'

export const UserProfile = () => {
  const { user, isAuthenticated, checkAuth } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    const checkIsAuth = async () => {
      switch (isAuthenticated) {
        case undefined: {
          await checkAuth()
          break
        }
        case false: {
          navigate('/unregistered')
          break
        }
      }
    }
    checkIsAuth()
  }, [isAuthenticated])

  return (
    <>
      <Typography component='h1' variant='h5'>Потребителски профил</Typography>
      { user !== null
        ? (<React.Fragment>
            <div>Имейл: { user?.email }</div>
            <div>Потвърден: { user?.confirmed ? 'да' : 'не'}</div>
            <LogoutButton />
            </React.Fragment>
          )
        : (
            <Typography component='h2' variant='h4'>Нещо не е на ред...</Typography>
          )
      }
    </>
  )
}
